import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from '@sidkik/authz';
import { ContainerComponent, HelpComponent } from '@sidkik/tenant-layout';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/analytics/dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: ContainerComponent,
    canActivate: [AuthenticatedGuard],
    children: [
      {
        canLoad: [AuthenticatedGuard],
        canActivate: [AuthenticatedGuard],
        path: 'help',
        component: HelpComponent
      },
      {
        canLoad: [AuthenticatedGuard],
        canActivate: [AuthenticatedGuard],
        path: 'analytics',
        loadChildren: () =>
          import('@sidkik/analytics').then((m) => m.AnalyticsModule),
      },
      {
        canLoad: [AuthenticatedGuard],
        canActivate: [AuthenticatedGuard],
        path: 'library',
        loadChildren: () =>
          import('@sidkik/library').then((m) => m.LibraryModule),
      },
      {
        canLoad: [AuthenticatedGuard],
        canActivate: [AuthenticatedGuard],
        path: 'community',
        loadChildren: () =>
          import('@sidkik/community').then((m) => m.CommunityModule),
      },
      {
        canLoad: [AuthenticatedGuard],
        canActivate: [AuthenticatedGuard],
        path: 'people',
        loadChildren: () =>
          import('@sidkik/people').then((m) => m.PeopleModule),
      },
      {
        canLoad: [AuthenticatedGuard],
        canActivate: [AuthenticatedGuard],
        path: 'catalog',
        loadChildren: () =>
          import('@sidkik/catalog').then((m) => m.CatalogModule),
      },
      {
        canLoad: [AuthenticatedGuard],
        canActivate: [AuthenticatedGuard],
        path: 'integrations',
        loadChildren: () =>
          import('@sidkik/integrations').then((m) => m.IntegrationsModule),
      },
      {
        canLoad: [AuthenticatedGuard],
        canActivate: [AuthenticatedGuard],
        path: 'config',
        loadChildren: () =>
          import('@sidkik/tenant-settings').then((m) => m.TenantSettingsModule),
      },
      {
        canLoad: [AuthenticatedGuard],
        canActivate: [AuthenticatedGuard],
        path: 'people',
        loadChildren: () =>
          import('@sidkik/people').then((m) => m.PeopleModule),
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('@sidkik/authz').then((m) => m.AuthzModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class RoutingModule {}
