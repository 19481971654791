import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import * as LayoutSelectors from './layout.selectors';
import * as LayoutActions from './layout.actions';

@Injectable()
export class LayoutFacade {
  stripeAccountError$ = this.store.pipe(
    select(LayoutSelectors.getStripeAccountError)
  );
  stripeAccount$ = this.store.pipe(select(LayoutSelectors.getStripeAccount));
  breadcrumbTrail$ = this.store.pipe(
    select(LayoutSelectors.getGlobalBreadcrumbTrail)
  );
  user$ = this.store.pipe(select(LayoutSelectors.getAuthzUser));

  loadStripeAccount() {
    this.store.dispatch(LayoutActions.loadStripeAccount());
  }

  constructor(private readonly store: Store) {}
}
