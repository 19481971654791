export const integrationsStateKey = 'integrations';
export const stripeStateKey = 'stripe';
export const stripeConfigStateKey = 'config';
export const activeCampaignStateKey = 'activeCampaign';
export const mailerStateKey = 'mailer';
export const activeCampaignConfigStateKey = 'config';
export const mailerConfigStateKey = 'config';
export const quickbooksStateKey = 'quickbooks';
export const quickbooksAccountStateKey = 'account';
export const quickbooksConfigStateKey = 'config';
export const quickbooksVendorStateKey = 'vendor';
export const vimeoStateKey = 'vimeo';
export const vimeoConfigStateKey = 'config';
export const slackStateKey = 'slack';
export const slackConfigStateKey = 'config';
