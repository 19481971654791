import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AppConfig,
  APP_CONFIG,
  HTTPTraceHeader,
  SpanTypes,
  TraceService,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface ThemeConfig {
  theme: string;
  branding: {
    siteName?: string;
    logo?: string;
    smallLogo?: string;
    favicon?: string;
  };
}

export interface AddonConfig {
  tawkTo?: {
    chatLink?: string;
    key?: string;
  };
  activeCampaignId?: string;
  linkedInInsightsId?: string;
  fbPixelId?: string;
  clarityId?: string;
}

export interface DomainAPI {
  updateTheme(theme: ThemeConfig, trace?: HTTPTraceHeader): Observable<null>;
}

@Injectable({
  providedIn: 'root',
})
export class DomainService implements DomainAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  timeoutMs = 20000;

  retryConfig = {
    retries: 1,
    backoffMs: 1000,
    retryDelayMs: 1000,
    tooBusyRetries: 2,
    checksumRetries: 2,
  };

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  updateTheme(theme: ThemeConfig, trace?: HTTPTraceHeader): Observable<null> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminUpdateTheme);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminUpdateTheme
      );
      internalTrace = true;
    }
    return this.http
      .put<null>(`${this.tenantConfig.api.endpoint}/admin/theme`, theme, {
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminUpdateTheme, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminUpdateTheme);
        })
      );
  }

  updateAddon(addon: AddonConfig, trace?: HTTPTraceHeader): Observable<null> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminUpdateAddon);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminUpdateAddon
      );
      internalTrace = true;
    }
    return this.http
      .put<null>(`${this.tenantConfig.api.endpoint}/admin/addon`, addon, {
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminUpdateAddon, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminUpdateAddon);
        })
      );
  }

  addSubdomain(domain: string, trace?: HTTPTraceHeader): Observable<null> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminAddSubdomain);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminAddSubdomain
      );
      internalTrace = true;
    }
    return this.http
      .put<null>(
        `${this.tenantConfig.api.endpoint}/admin/subdomain`,
        { value: domain },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminAddSubdomain, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminAddSubdomain);
        })
      );
  }

  addCustomDomain(domain: string, trace?: HTTPTraceHeader): Observable<null> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminAddCustomDomain);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminAddCustomDomain
      );
      internalTrace = true;
    }
    return this.http
      .put<null>(
        `${this.tenantConfig.api.endpoint}/admin/custom-domain`,
        { value: domain },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminAddCustomDomain, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminAddCustomDomain);
        })
      );
  }

  verifyCustomDomainDNS(
    domain: string,
    trace?: HTTPTraceHeader
  ): Observable<{ verified: boolean; issue: string }> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminVerifyCustomDomain);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminVerifyCustomDomain
      );
      internalTrace = true;
    }
    return this.http
      .post<{ verified: boolean; issue: string }>(
        `${this.tenantConfig.api.endpoint}/admin/custom-domain/verify-dns`,
        { value: domain },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminVerifyCustomDomain, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminVerifyCustomDomain);
        })
      );
  }

  requestProvisionForCustomDomain(
    domain: string,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(
        SpanTypes.adminRequestProvisionForCustomDomain
      );
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminRequestProvisionForCustomDomain
      );
      internalTrace = true;
    }
    return this.http
      .post<void>(
        `${this.tenantConfig.api.endpoint}/admin/custom-domain/provision`,
        { value: domain },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.adminRequestProvisionForCustomDomain,
              err
            );
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(
              SpanTypes.adminRequestProvisionForCustomDomain
            );
        })
      );
  }

  deleteSubdomain(domain: string, trace?: HTTPTraceHeader): Observable<null> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminDeleteSubdomain);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminDeleteSubdomain
      );
      internalTrace = true;
    }
    return this.http
      .delete<null>(`${this.tenantConfig.api.endpoint}/admin/subdomain`, {
        headers: this.processHeaders(trace),
        body: { value: domain },
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminDeleteSubdomain, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminDeleteSubdomain);
        })
      );
  }

  deleteCustomDomain(
    domain: string,
    trace?: HTTPTraceHeader
  ): Observable<null> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminDeleteCustomDomain);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminDeleteCustomDomain
      );
      internalTrace = true;
    }
    return this.http
      .delete<null>(`${this.tenantConfig.api.endpoint}/admin/custom-domain`, {
        headers: this.processHeaders(trace),
        body: { value: domain },
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminDeleteCustomDomain, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminDeleteCustomDomain);
        })
      );
  }
}
