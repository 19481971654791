import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ActiveCampaignConfig,
  ActiveCampaignConnection,
  AppConfig,
  APP_CONFIG,
  TraceService,
  HTTPTraceHeader,
  SpanTypes,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface ActiveCampaignAPI {
  getConfig(): Observable<ActiveCampaignConfig>;
  updateConfig(config: ActiveCampaignConfig): Observable<ActiveCampaignConfig>;
  getConnection(): Observable<ActiveCampaignConnection>;
  createConnection(
    connection: ActiveCampaignConnection
  ): Observable<ActiveCampaignConnection>;
}

@Injectable({
  providedIn: 'root',
})
export class ActiveCampaignService implements ActiveCampaignAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  getConfig(trace?: HTTPTraceHeader): Observable<ActiveCampaignConfig> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminACGetConfig);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminACGetConfig
      );
      internalTrace = true;
    }
    return this.http
      .get<ActiveCampaignConfig>(
        `${this.tenantConfig.api.endpoint}/admin/active-campaign/config`,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminACGetConfig, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminACGetConfig);
        })
      );
  }

  updateConfig(
    config: ActiveCampaignConfig,
    trace?: HTTPTraceHeader
  ): Observable<ActiveCampaignConfig> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminACUpdateConfig);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminACUpdateConfig
      );
      internalTrace = true;
    }
    return this.http
      .put<ActiveCampaignConfig>(
        `${this.tenantConfig.api.endpoint}/admin/active-campaign/config`,
        { ...config },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminACUpdateConfig, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminACUpdateConfig);
        })
      );
  }

  importCustomer(customerId: string, trace?: HTTPTraceHeader): Observable<any> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminACImportCustomer);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminACImportCustomer
      );
      internalTrace = true;
    }
    return this.http
      .post<ActiveCampaignConfig>(
        `${this.tenantConfig.api.endpoint}/admin/active-campaign/customer/import`,
        { customerID: customerId },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminACImportCustomer, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminACImportCustomer);
        })
      );
  }

  importOrder(
    customerId: string,
    orderId: string,
    trace?: HTTPTraceHeader
  ): Observable<any> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminACImportOrder);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminACImportOrder
      );
      internalTrace = true;
    }
    return this.http
      .post<ActiveCampaignConfig>(
        `${this.tenantConfig.api.endpoint}/admin/active-campaign/order/import`,
        { customerID: customerId, orderID: orderId },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminACImportOrder, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminACImportOrder);
        })
      );
  }

  getConnection(trace?: HTTPTraceHeader): Observable<ActiveCampaignConnection> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminACGetConnection);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminACGetConnection
      );
      internalTrace = true;
    }
    return this.http
      .get<ActiveCampaignConnection>(
        `${this.tenantConfig.api.endpoint}/admin/active-campaign/connection`,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminACGetConnection, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminACGetConnection);
        })
      );
  }

  testConnection(
    account: string,
    key: string,
    trace?: HTTPTraceHeader
  ): Observable<ActiveCampaignConnection> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminACTestConnection);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminACTestConnection
      );
      internalTrace = true;
    }
    return this.http
      .post<ActiveCampaignConnection>(
        `${this.tenantConfig.api.endpoint}/admin/active-campaign/connection/test`,
        { account, key },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminACTestConnection, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminACTestConnection);
        })
      );
  }

  createConnection(
    connection: ActiveCampaignConnection,
    trace?: HTTPTraceHeader
  ): Observable<ActiveCampaignConnection> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminACCreateConnection);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminACCreateConnection
      );
      internalTrace = true;
    }
    return this.http
      .post<ActiveCampaignConnection>(
        `${this.tenantConfig.api.endpoint}/admin/active-campaign/connection`,
        { ...connection },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminACCreateConnection, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminACCreateConnection);
        })
      );
  }
}
