import { createReducer, on, Action } from '@ngrx/store';

import * as LayoutActions from './layout.actions';
import { StripeAccount } from '@sidkik/global';

export const LAYOUT_FEATURE_KEY = 'tenant-layout';

export interface LayoutState {
  stripe?: StripeAccount | undefined; // which Layout record has been selected
  error?: any | null; // last none error (if any)
}

export interface LayoutPartialState {
  readonly [LAYOUT_FEATURE_KEY]: LayoutState;
}

export const initialLayoutState: LayoutState = {
  // set initial required properties
  stripe: undefined,
  error: null,
};

const reducer = createReducer(
  initialLayoutState,
  on(LayoutActions.loadStripeAccountSuccess, (state, { stripe }) => ({
    ...state,
    stripe,
  })),
  on(LayoutActions.loadStripeAccountFailure, (state, { error }) => ({
    ...state,
    stripe: undefined,
    error,
  }))
);

export function layoutReducer(state: LayoutState | undefined, action: Action) {
  return reducer(state, action);
}
