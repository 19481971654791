import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  AppConfig,
  APP_CONFIG,
  HTTPTraceHeader,
  TraceService,
  SpanTypes,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface Stats {
  stats: {
    blocks: number;
    bounce_drops: number;
    bounces: number;
    clicks: number;
    deferred: number;
    delivered: number;
    invalid_emails: number;
    opens: number;
    processed: number;
    requests: number;
    spam_report_drops: number;
    spam_reports: number;
    unique_clicks: number;
    unique_opens: number;
    unsubscribe_drops: number;
    unsubscribes: number;
  };
}

export interface SendGridAPI {
  getStats(
    apiKey: string,
    startDate?: string,
    trace?: HTTPTraceHeader
  ): Observable<Stats>;
  configureWebhooks(trace?: HTTPTraceHeader): Observable<void>;
}

@Injectable({
  providedIn: 'root',
})
export class SendGridService implements SendGridAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  timeoutMs = 20000;

  retryConfig = {
    retries: 1,
    backoffMs: 1000,
    retryDelayMs: 1000,
    tooBusyRetries: 2,
    checksumRetries: 2,
  };

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  configureWebhooks(trace?: HTTPTraceHeader): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.sendGridConfigureWebhooks);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.sendGridConfigureWebhooks
      );
      internalTrace = true;
    }
    return this.http
      .post<void>(
        `${this.tenantConfig.api.endpoint}/admin/mailer/sendgrid/setup`,
        {},
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.sendGridConfigureWebhooks, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.sendGridConfigureWebhooks);
        })
      );
  }

  getStats(
    apiKey: string,
    startDate?: string,
    trace?: HTTPTraceHeader
  ): Observable<Stats> {
    if (!startDate) {
      // get stats for last 30 days
      const date = new Date();
      date.setDate(date.getDate() - 30);
      startDate = date.toISOString().split('T')[0];
    }
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.sendGridGetStats);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.sendGridGetStats
      );
      internalTrace = true;
    }
    return this.http
      .post<Stats>(
        `${this.tenantConfig.api.endpoint}/admin/mailer/sendgrid/stats`,
        {
          apiKey,
          startDate,
        },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.sendGridGetStats, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.sendGridGetStats);
        })
      );
  }
}
