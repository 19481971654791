import { Injectable } from '@angular/core';
import * as LayoutActions from './layout.actions';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { StripeService } from '@sidkik/sidkik-api';
import { StripeConfigProperties } from '@sidkik/db';
import { take, map, filter } from 'rxjs';
import { fetch } from '@nx/angular';
import { LayoutFacade } from './layout.facade';
@Injectable()
export class LayoutEffects implements OnInitEffects {
  /** Fetch Actions */
  loadConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.loadStripeAccount),
      fetch({
        id: (a: ReturnType<typeof LayoutActions.loadStripeAccount>) => a.type,
        run: (a: ReturnType<typeof LayoutActions.loadStripeAccount>) => {
          return this.stripeAPI.getAccount().pipe(
            take(1),
            map((account) => {
              const config: StripeConfigProperties = {
                id: account.id.toString(),
                data: {
                  active: account.active,
                  email: account.email,
                  id: account.id.toString(),
                  type: account.type,
                  chargesEnabled: account.chargesEnabled,
                  cardsAccepted: account.cardsAccepted,
                },
              } as unknown as StripeConfigProperties;
              return LayoutActions.loadStripeAccountSuccess({
                stripe: account,
              });
            })
          );
        },
        onError: (_, error: any) => {
          return LayoutActions.loadStripeAccountFailure({
            error: error.message,
          });
        },
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly stripeAPI: StripeService,
    private readonly layoutFacade: LayoutFacade
  ) {}

  ngrxOnInitEffects() {
    this.layoutFacade.user$
      .pipe(
        filter((user) => user !== null && user !== undefined),
        take(1)
      )
      .subscribe(() => {
        this.layoutFacade.loadStripeAccount();
      });
    return LayoutActions.noop();
  }
}
