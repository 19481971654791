import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  AppConfig,
  APP_CONFIG,
  HTTPTraceHeader,
  SpanTypes,
  TraceService,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface AssetAPI {
  markAssetUploaded(id: string, trace?: HTTPTraceHeader): Observable<null>;
  syncAsset(id: string, trace?: HTTPTraceHeader): Observable<null>;
}

@Injectable({
  providedIn: 'root',
})
export class AssetService implements AssetAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  timeoutMs = 20000;

  retryConfig = {
    retries: 1,
    backoffMs: 1000,
    retryDelayMs: 1000,
    tooBusyRetries: 2,
    checksumRetries: 2,
  };

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  markAssetUploaded(id: string, trace?: HTTPTraceHeader): Observable<null> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminAssetsMarkUploaded);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminAssetsMarkUploaded
      );
      internalTrace = true;
    }
    return this.http
      .put<null>(
        `${this.tenantConfig.api.endpoint}/admin/asset/${id}/uploaded`,
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminAssetsMarkUploaded, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminAssetsMarkUploaded);
        })
      );
  }

  syncAsset(id: string, trace?: HTTPTraceHeader): Observable<null> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminAssetSync);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminAssetSync
      );
      internalTrace = true;
    }
    return this.http
      .put<null>(`${this.tenantConfig.api.endpoint}/admin/asset/${id}/sync`, {
        headers: this.processHeaders(trace),
      })
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminAssetSync, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace && this.traceService.endSpan(SpanTypes.adminAssetSync);
        })
      );
  }
}
