import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
  HttpContext,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { AuthzService } from '../services/authz.service';

const disableBearerToken = new HttpContextToken<boolean>(() => false);

export function disableBearerTokenAuthentication() {
  return new HttpContext().set(disableBearerToken, true);
}

/**
 * To avoid adding auth tokens
 *
 * return this.http.get('....', {
 *     context: disableBearerTokenAuthentication()
 * })
 *
 */

/**
 * Authentication interceptor
 */
@Injectable()
export class AuthzHttpInterceptor implements HttpInterceptor {
  constructor(private readonly authz: AuthzService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.context.get(disableBearerToken) ?? false) {
      return next.handle(request);
    }
    return this.authz.authz$.pipe(
      take(1),
      map((a) => a?.idToken?.token ?? ''),
      switchMap((token) => {
        // check to see if Authorization header is already set
        if (request.headers.has('Authorization')) {
          return next.handle(request);
        }
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
        return next.handle(request);
      })
    );
  }
}
