<div *ngIf="!(stripeActive$ | async)" class="tw-bg-info-400 tw-px-6 tw-py-2.5 sm:tw-px-3.5 sm:before:tw-flex-1">
  <p class="tw-text-sm tw-leading-6 tw-w-full tw-text-center tw-text-white">
    <a [routerLink]="['/integrations/stripe/connect']">
      <strong class="tw-font-semibold">Welcome aboard! Please enable the stripe integration to allow signups and
        purchases.</strong><span aria-hidden="true"> &rarr;</span>
    </a>
  </p>
</div>
<div class="tw-flex tw-h-full">
  <div *ngIf='isOffCanvasMenu' class="tw-relative tw-z-40 lg:tw-hidden" role="dialog" aria-modal="true">
    <div @AnimationTrigger0 *ngIf='isOffCanvasMenu' class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75">
    </div>
    <div class="tw-fixed tw-inset-0 tw-z-40 tw-flex">
      <div @AnimationTrigger1 *ngIf='isOffCanvasMenu'
        class="tw-relative tw-flex tw-w-full tw-max-w-xs tw-flex-1 tw-flex-col tw-bg-white focus:tw-outline-none">
        <div @AnimationTrigger2 *ngIf='isOffCanvasMenu' class="tw-absolute tw-top-0 tw-right-0 -tw-mr-12 tw-pt-2">
          <button type="button" (click)="toggleOffCanvasMenu()"
            class="tw-ml-1 tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-white">
            <span class="tw-sr-only">Close sidebar</span>
            <svg class="tw-h-6 tw-w-6 tw-text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="tw-h-0 tw-flex-1 tw-overflow-y-auto tw-pt-5 tw-pb-4">
          <div class="tw-flex tw-flex-shrink-0 tw-items-center tw-px-4">
            <img class="tw-h-8 tw-w-auto" [src]="condensedlogoUrl" alt="Workflow">
          </div>
          <nav class="tw-mt-5 tw-flex-1" aria-label="Sidebar">
            <div class="">
              <a [routerLink]="['/analytics/dashboard']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="dashboard"></sidkik-icon>
                Dashboard
              </a>
              <div class="sidebar-section">Create</div>
              <a [routerLink]="['/library/assets']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="assets"></sidkik-icon>
                Assets
              </a>
              <a [routerLink]="['/library/courses']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="courses"></sidkik-icon>
                Courses
              </a>
              <a [routerLink]="['/library/challenges']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="challenges"></sidkik-icon>
                Challenges
              </a>
              <a [routerLink]="['/library/pages']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="pages"></sidkik-icon>
                Pages
              </a>
              <a [routerLink]="['/library/posts']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="posts"></sidkik-icon>
                Posts
              </a>
              <div class="sidebar-section">Interact</div>
              <a [routerLink]="['/community/spaces']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="space"></sidkik-icon>
                Spaces
              </a>
              <a [routerLink]="['/community/comments']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="moderation"></sidkik-icon>
                Moderation
              </a>
              <a [routerLink]="['/library/emails']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="email"></sidkik-icon>
                Emails
              </a>
              <a [routerLink]="['/library/forms']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="form"></sidkik-icon>
                Forms
              </a>
              <a [routerLink]="['/library/workflows']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="workflow"></sidkik-icon>
                Automations
              </a>
              <div class="sidebar-section">Sell</div>
              <a [routerLink]="['/catalog/products']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="products"></sidkik-icon>
                <div class="tw-flex tw-w-full tw-items-center">
                  <div>Products</div>
                  <div class="tw-flex-grow"></div>
                  <div *ngIf="!(stripeActive$ | async)" sidkikTooltip="Enable stripe to create products"
                    class="tw-w-fit">
                    <sidkik-icon class="alert" svgIcon="alert_circle"></sidkik-icon>
                  </div>
                </div>
              </a>
              <a [routerLink]="['/catalog/coupons']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="coupon"></sidkik-icon>
                <div class="tw-flex tw-w-full tw-items-center">
                  <div>Coupons</div>
                  <div class="tw-flex-grow"></div>
                  <div *ngIf="!(stripeActive$ | async)" sidkikTooltip="Enable stripe to create coupons"
                    class="tw-w-fit">
                    <sidkik-icon class="alert" svgIcon="alert_circle"></sidkik-icon>
                  </div>
                </div>
              </a>
              <div class="sidebar-section">People</div>
              <a [routerLink]="['/people/customers']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="customers"></sidkik-icon>
                Customers
              </a>
              <a [routerLink]="['/people/affiliates']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="affiliates"></sidkik-icon>
                Affiliates
              </a>
              <div class="sidebar-section">Settings</div>
              <a [routerLink]="['/config/networking']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="gear"></sidkik-icon>
                General
              </a>
              <a [routerLink]="['/integrations']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <sidkik-icon svgIcon="integrations"></sidkik-icon>
                <div class="tw-flex tw-w-full tw-items-center">
                  <div>Integrations</div>
                  <div class="tw-flex-grow"></div>
                  <div *ngIf="!(stripeActive$ | async)"
                    sidkikTooltip="Stripe integration must be active to allow signups and purchases" class="tw-w-fit">
                    <sidkik-icon class="alert" svgIcon="alert_circle"></sidkik-icon>
                  </div>
                </div>
              </a>
            </div>
          </nav>
        </div>
        <sidkik-user-info></sidkik-user-info>
      </div>
      <div class=" tw-w-14 tw-flex-shrink-0" aria-hidden="true">
      </div>
    </div>
  </div>
  <div class="tw-hidden lg:tw-flex lg:tw-flex-col lg:tw-flex-shrink-0 tw-bg-white">
    <div class="tw-flex tw-flex-shrink-0 tw-items-center tw-justify-center tw-h-[88px] tw-px-0">
      <img class="tw-w-24" [src]="logoUrl" alt="Workflow">
    </div>
    <div class="tw-flex tw-flex-grow">
      <div class="tw-hidden lg:tw-flex lg:tw-flex-shrink-0">
        <div class="tw-flex tw-w-14 tw-flex-col tw-transition-all tw-delay-300" [class.tw-w-36]="expandMain">
          <div class="tw-flex tw-min-h-0 tw-flex-1 tw-flex-col tw-shadow-sm tw-bg-white">
            <div class="tw-flex tw-flex-1 tw-flex-col tw-pt-0 tw-pb-4">
              <nav class="tw-mt-5 tw-flex tw-flex-col tw-flex-1" aria-label="Sidebar">
                <div class="">
                  <a [routerLink]="['/analytics/dashboard']" class="sidebar-item-main"
                    [class.active]="activeSection === sections.Analytics" (click)="toggleSection(sections.Analytics)"
                    sidkikTooltip="Analytics" [showDelay]="50" [direction]="'side'" [disappear]="expandMain">
                    <sidkik-icon svgIcon="analytics"></sidkik-icon>
                  </a>
                  <a [routerLink]="['/library/assets']" class="sidebar-item-main"
                    (click)="toggleSection(sections.Create)" [class.active]="activeSection === sections.Create"
                    sidkikTooltip="Create" [showDelay]="50" [direction]="'side'" [disappear]="expandMain">
                    <sidkik-icon svgIcon="create"></sidkik-icon>
                  </a>
                  <a [routerLink]="['/library/emails']" class="sidebar-item-main"
                    (click)="toggleSection(sections.Interact)" [class.active]="activeSection === sections.Interact"
                    sidkikTooltip="Interact" [showDelay]="50" [direction]="'side'" [disappear]="expandMain">
                    <sidkik-icon svgIcon="engage"></sidkik-icon>
                  </a>
                  <a [routerLink]="['/catalog/products']" class="sidebar-item-main"
                    (click)="toggleSection(sections.Sell)" [class.active]="activeSection === sections.Sell"
                    sidkikTooltip="Sell" [showDelay]="50" [direction]="'side'" [disappear]="expandMain">
                    <sidkik-icon svgIcon="sell"></sidkik-icon>
                  </a>
                  <a [routerLink]="['/people/customers']" class="sidebar-item-main"
                    (click)="toggleSection(sections.People)" [class.active]="activeSection === sections.People"
                    sidkikTooltip="People" [showDelay]="50" [direction]="'side'" [disappear]="expandMain">
                    <sidkik-icon svgIcon="people"></sidkik-icon>
                  </a>
                  <a [routerLink]="['/config/networking']" class="sidebar-item-main"
                    [class.active]="activeSection === sections.Settings" (click)="toggleSection(sections.Settings)"
                    sidkikTooltip="Settings" [showDelay]="50" [direction]="'side'" [disappear]="expandMain">
                    <sidkik-icon svgIcon="gear"></sidkik-icon>
                  </a>

                  <!-- <a *ngIf="!(stripeActive$ | async)" class="sidebar-item">
                <sidkik-icon svgIcon="coupon"></sidkik-icon>
                <div class="tw-flex tw-w-full tw-items-center">
                  <div>Coupons</div>
                  <div class="tw-flex-grow"></div>
                  <div *ngIf="!(stripeActive$ | async)" sidkikTooltip="Enable stripe to create coupons"
                    class="tw-w-fit">
                    <sidkik-icon class="alert" svgIcon="alert_circle"></sidkik-icon>
                  </div>
                </div>
              </a> -->
                </div>
                <div class="tw-flex-grow"></div>
                <div>
                  <a [routerLink]="['/help']" class="sidebar-item-main" [class.active]="activeSection === sections.Help"
                    (click)="toggleSection(sections.Help)" sidkikTooltip="Help" [showDelay]="50" [direction]="'side'"
                    [disappear]="expandMain">
                    <sidkik-icon svgIcon="help"></sidkik-icon>
                  </a>
                </div>
              </nav>
            </div>

          </div>
        </div>

      </div>
      <div class="tw-hidden tw-relative lg:tw-flex lg:tw-flex-shrink-0 tw-z-0">
        <div class="tw-flex tw-w-44 tw-flex-col">
          <div class="tw-flex tw-min-h-0 tw-flex-1 tw-flex-col tw-border-r tw-border-gray-200 tw-bg-gray-100">
            <div class="tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto tw-pt-0 tw-pb-4">
              <nav class="tw-mt-0 tw-flex-1" aria-label="Sidebar">
                <div [class.tw-hidden]="activeSection !== sections.Analytics"
                  [class.tw-block]="activeSection === sections.Analytics">
                  <div class="sidebar-section">Analytics</div>
                  <a [routerLink]="['/analytics/dashboard']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="dashboard"></sidkik-icon> -->
                    Dashboard
                  </a>
                </div>
                <div [class.tw-hidden]="activeSection !== sections.Create"
                  [class.tw-block]="activeSection === sections.Create">
                  <div class="sidebar-section">Create</div>
                  <a [routerLink]="['/library/assets']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="assets"></sidkik-icon> -->
                    Assets

                  </a>
                  <a [routerLink]="['/library/courses']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="courses"></sidkik-icon> -->
                    Courses
                  </a>
                  <a [routerLink]="['/library/challenges']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="challenges"></sidkik-icon> -->
                    Challenges
                  </a>
                  <a [routerLink]="['/library/pages']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="pages"></sidkik-icon> -->
                    Pages
                  </a>
                  <a [routerLink]="['/library/posts']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="posts"></sidkik-icon> -->
                    Posts
                  </a>
                  <a [routerLink]="['/library/flows']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="posts"></sidkik-icon> -->
                    Flows
                  </a>
                </div>
                <div [class.tw-hidden]="activeSection !== sections.Interact"
                  [class.tw-block]="activeSection === sections.Interact">
                  <div class="sidebar-section">Interact</div>
                  <a [routerLink]="['/library/emails']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="email"></sidkik-icon> -->
                    Emails
                  </a>
                  <a [routerLink]="['/library/forms']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="form"></sidkik-icon> -->
                    Forms
                  </a>
                  <a [routerLink]="['/library/workflows']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="workflow"></sidkik-icon> -->
                    Automations
                  </a>
                  <div class="sidebar-section">Marketing</div>
                  <a [routerLink]="['/people/campaigns']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="space"></sidkik-icon> -->
                    Campaigns
                  </a>
                  <a [routerLink]="['/people/lists']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="space"></sidkik-icon> -->
                    Lists
                  </a>
                  <a [routerLink]="['/people/tags']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="space"></sidkik-icon> -->
                    Tags
                  </a>
                  <div class="sidebar-section">Community</div>
                  <a [routerLink]="['/community/spaces']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="space"></sidkik-icon> -->
                    Spaces
                  </a>
                  <a [routerLink]="['/community/comments']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="moderation"></sidkik-icon> -->
                    Moderation
                  </a>
                </div>
                <div [class.tw-hidden]="activeSection !== sections.Sell"
                  [class.tw-block]="activeSection === sections.Sell">
                  <div class="sidebar-section">Sell</div>
                  <a [routerLink]="['/catalog/products']" *ngIf="(stripeActive$ | async)" routerLinkActive="active"
                    class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="products"></sidkik-icon> -->
                    Products
                  </a>
                  <a *ngIf="!(stripeActive$ | async)" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="products"></sidkik-icon> -->
                    <div class="tw-flex tw-w-full tw-items-center">
                      <div>Products</div>
                      <div class="tw-flex-grow"></div>
                      <div *ngIf="!(stripeActive$ | async)" sidkikTooltip="Enable stripe to create products"
                        class="tw-w-fit">
                        <sidkik-icon class="alert" svgIcon="alert_circle"></sidkik-icon>
                      </div>
                    </div>
                  </a>
                  <a [routerLink]="['/catalog/coupons']" *ngIf="(stripeActive$ | async)" routerLinkActive="active"
                    class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="coupon"></sidkik-icon> -->
                    Coupons
                  </a>
                  <a *ngIf="!(stripeActive$ | async)" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="coupon"></sidkik-icon> -->
                    <div class="tw-flex tw-w-full tw-items-center">
                      <div>Coupons</div>
                      <div class="tw-flex-grow"></div>
                      <div *ngIf="!(stripeActive$ | async)" sidkikTooltip="Enable stripe to create coupons"
                        class="tw-w-fit">
                        <sidkik-icon class="alert" svgIcon="alert_circle"></sidkik-icon>
                      </div>
                    </div>
                  </a>
                </div>
                <div [class.tw-hidden]="activeSection !== sections.People"
                  [class.tw-block]="activeSection === sections.People">
                  <div class="sidebar-section">People</div>
                  <a [routerLink]="['/people/customers']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="customers"></sidkik-icon> -->
                    Customers
                  </a>
                  <a [routerLink]="['/people/affiliates']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="affiliates"></sidkik-icon> -->
                    Affiliates
                  </a>
                </div>
                <div [class.tw-hidden]="activeSection !== sections.Settings"
                  [class.tw-block]="activeSection === sections.Settings">
                  <div class="sidebar-section">Settings</div>
                  <a [routerLink]="['/config/networking']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="gear"></sidkik-icon> -->
                    Domains
                  </a>
                  <a [routerLink]="['/config/navigation']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="gear"></sidkik-icon> -->
                    Navigation
                  </a>
                  <div class="sidebar-section">Appearance</div>
                  <a [routerLink]="['/config/theme']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="gear"></sidkik-icon> -->
                    Theme
                  </a>
                  <div class="sidebar-section">Community</div>
                  <a [routerLink]="['/config/community']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="gear"></sidkik-icon> -->
                    Moderation
                  </a>
                  <div class="sidebar-section">Integrations</div>
                  <a [routerLink]="['/integrations/stripe']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="integrations"></sidkik-icon> -->
                    <div class="tw-flex tw-w-full tw-items-center">
                      <div>Stripe</div>
                      <div class="tw-flex-grow"></div>
                      <div *ngIf="!(stripeActive$ | async)"
                        sidkikTooltip="Stripe integration must be active to allow signups and purchases"
                        class="tw-w-fit">
                        <sidkik-icon class="alert" svgIcon="alert_circle"></sidkik-icon>
                      </div>
                    </div>
                  </a>
                  <a [routerLink]="['/integrations/quickbooks/config']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="gear"></sidkik-icon> -->
                    Quickbooks
                  </a>
                  <a [routerLink]="['/integrations/active-campaign/config']" routerLinkActive="active"
                    class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="gear"></sidkik-icon> -->
                    ActiveCampaign
                  </a>
                  <a [routerLink]="['/integrations/vimeo/config']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="gear"></sidkik-icon> -->
                    Vimeo
                  </a>
                  <a [routerLink]="['/integrations/mailer/config']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="gear"></sidkik-icon> -->
                    Mailer
                  </a>
                  <a [routerLink]="['/integrations/slack/config']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="gear"></sidkik-icon> -->
                    Slack
                  </a>
                  <div class="sidebar-section">Extras</div>
                  <a [routerLink]="['/config/accounting']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="gear"></sidkik-icon> -->
                    Accounting
                  </a>
                  <a [routerLink]="['/config/addon']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="gear"></sidkik-icon> -->
                    Addons
                  </a>
                  <a [routerLink]="['/config/debug']" routerLinkActive="active" class="sidebar-item">
                    <!-- <sidkik-icon svgIcon="gear"></sidkik-icon> -->
                    Debug
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sidkik-user-info></sidkik-user-info>
  </div>
  <div
    class="tw-flex tw-min-w-0 tw-flex-1 tw-flex-col tw-overflow-x-hidden tw-overflow-y-auto lg:tw-max-w-[calc(tw-100vw-16rem)]"
    cdkScrollable id="scrollable-container">
    <div class="lg:tw-hidden">
      <div
        class="tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-px-4 tw-py-1.5">
        <div>
          <img class="tw-h-8 tw-w-auto" [src]="logoUrl" alt="Workflow">
        </div>
        <div>
          <button type="button" (click)="toggleOffCanvasMenu()"
            class="-tw-mr-3 tw-inline-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-md tw-text-gray-500 hover:tw-text-gray-900">
            <span class="tw-sr-only">Open sidebar</span>
            <svg class="tw-h-6 tw-w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="tw-relative tw-z-0 tw-flex tw-flex-col tw-flex-grow">
      <sidkik-breadcrumb [trail]="breadcrumbTrail$ | async" [includeHome]="false"></sidkik-breadcrumb>
      <div class="tw-flex tw-flex-grow">
        <main class="tw-relative tw-z-0 tw-flex-1 tw-max-w-full focus:tw-outline-none">
          <div class="">
            <router-outlet></router-outlet>
          </div>
        </main>
      </div>
    </div>
    <div class="tw-mt-16 tw-flex  tw-p-6 sm:tw-mt-20 lg:tw-mt-24">
      <div class="tw-flex-grow"> </div>
      <p class="tw-text-xs tw-leading-5 tw-text-gray-300">Powered by: <a
          class="tw-text-gray-400 hover:tw-text-gray-500 tw-underline" href="https://sidkik.com"
          target="_blank">Sidkik</a> <span (click)="enableDebug()"> v{{version}}</span></p>
    </div>
  </div>
</div>