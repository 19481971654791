import { Storage, StorageProperties } from '../storage';
import { Asset } from '../library';
import { Meta } from '../meta';
import { ChallengeSentiment } from './challenge-sentiment';
import { ChallengeSection } from './challenge-section';
import { EntityType } from '@sidkik/global';

export interface ChallengeData {
  title: string;
  image?: Asset;
  video?: Asset;
  tags?: string[];
  synopsis: string;
  description?: string;
  numberOfEvents: number;
  durationInDays: number;
  fixedStart: boolean;
  start?: number;
  hasCategories: boolean;
  categoryList?: string[];
  active: boolean;
  multiple: boolean;
  sentiments?: ChallengeSentiment[];
  sections?: ChallengeSection[];
}

export interface ChallengeProperties extends StorageProperties {
  data: ChallengeData;
  meta: Meta;
  id: string;
}

export class Challenge extends Storage implements ChallengeProperties {
  public override data!: ChallengeData;

  constructor(options?: ChallengeProperties, user?: string) {
    super(options, user, EntityType.Challenge);
    this.update(options?.data);
  }

  public update(data?: ChallengeData): void {
    const {
      title = '',
      image,
      video,
      tags = [],
      synopsis = '',
      description = '',
      durationInDays = 365,
      start = 0,
      fixedStart = false,
      hasCategories = false,
      numberOfEvents = 100,
      categoryList = [],
      active = true,
      sentiments = [],
      multiple = false,
      sections = [],
    } = data || {};
    this.data.title = title;
    this.data.image = image;
    this.data.video = video;
    this.data.tags = tags;
    this.data.synopsis = synopsis;
    this.data.description = description;
    this.data.durationInDays = durationInDays;
    this.data.fixedStart = fixedStart;
    this.data.start = start;
    this.data.numberOfEvents = numberOfEvents;
    this.data.hasCategories = hasCategories;
    this.data.categoryList = categoryList;
    this.data.active = active;
    this.data.sentiments = sentiments;
    this.data.multiple = multiple;
    this.data.sections = sections;
  }
}
