import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { BRANDING_CONFIG, BrandingConfig } from '@sidkik/global';
import { Observable, map } from 'rxjs';
import { LayoutFacade } from '../../+state/layout.facade';
import { isPlatformBrowser } from '@angular/common';
import { NotificationService } from '@sidkik/ui';
import { Router } from '@angular/router';

export enum Section {
  Analytics = 'analytics',
  Create = 'create',
  Interact = 'interact',
  Sell = 'sell',
  People = 'people',
  Settings = 'settings',
  Help = 'help',
}

@Component({
  selector: 'sidkik-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  animations: [
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease', style({ opacity: 0 })),
      ]),
    ]),
    trigger('AnimationTrigger1', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
    trigger('AnimationTrigger2', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ContainerComponent implements OnInit {
  condensedlogoUrl = this.brandingConfig.condensedLogo;
  logoUrl = this.brandingConfig.fullLogo;
  isOffCanvasMenu = false;
  now = Date.now();
  breadcrumbTrail$!: Observable<any>;
  debugCounter = 0;
  stripeActive$!: Observable<boolean>;

  activeSection = Section.Analytics;

  sections = Section;
  currentPath: string;
  expandMain = false;

  hoverMainTimeout: any;

  constructor(
    @Inject(BRANDING_CONFIG) private brandingConfig: BrandingConfig,
    @Inject('APP_VERSION') public version: string,
    private layoutFacade: LayoutFacade,
    @Inject(PLATFORM_ID) private platformId: any,
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.currentPath = this.router.url;
    this.setInitialSection(this.currentPath);
  }

  ngOnInit() {
    this.breadcrumbTrail$ = this.layoutFacade.breadcrumbTrail$;
    this.stripeActive$ = this.layoutFacade.stripeAccount$.pipe(
      map((a) => a?.active ?? false)
    );
  }

  toggleOffCanvasMenu() {
    this.isOffCanvasMenu = !this.isOffCanvasMenu;
  }

  toggleSection(section: Section) {
    this.activeSection = section;
    this.leaveMain();
  }

  enableDebug() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.debugCounter > 5) {
        localStorage.setItem('ROARR_LOG', 'true');
        this.notificationService.showInfo(
          'Debugging enabled',
          'See console. Must refresh page to take effect.'
        );
      }
      this.debugCounter++;
      return;
    }
  }

  enterMain() {
    this.hoverMainTimeout = setTimeout(() => {
      this.expandMain = true;
    }, 600);
  }

  leaveMain() {
    clearTimeout(this.hoverMainTimeout);
    this.expandMain = false;
  }

  setInitialSection(path: string) {
    let section = Section.Analytics;
    switch (true) {
      case path.startsWith('/library/posts'):
      case path.startsWith('/library/pages'):
      case path.startsWith('/library/challenges'):
      case path.startsWith('/library/courses'):
      case path.startsWith('/library/assets'):
      case path.startsWith('/library/flows'):
        section = Section.Create;
        break;
      case path.startsWith('/library/emails'):
      case path.startsWith('/library/forms'):
      case path.startsWith('/library/workflows'):
      case path.startsWith('/community/spaces'):
      case path.startsWith('/community/comments'):
      case path.startsWith('/people/campaigns'):
      case path.startsWith('/people/campaign-runs'):
      case path.startsWith('/people/lists'):
      case path.startsWith('/people/tags'):
        section = Section.Interact;
        break;
      case path.startsWith('/catalog/products'):
      case path.startsWith('/catalog/coupons'):
        section = Section.Sell;
        break;
      case path.startsWith('/people/customers'):
      case path.startsWith('/people/affiliates'):
        section = Section.People;
        break;
      case path.startsWith('/config'):
      case path.startsWith('/integrations'):
        section = Section.Settings;
        break;
      case path.startsWith('/help'):
        section = Section.Help;
        break;
    }

    this.toggleSection(section);
  }
}
