import { AssetProperties } from '.';
import { StorageProperties, Storage } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';
import { CourseWithProgress } from 'libs/my-activities/src/lib/models/course-with-progress';
import { ChallengeWithProgress } from 'libs/my-activities/src/lib/models/challenge-with-progress';
import { ChallengeProperties } from '../challenges';

export interface FlowSectionData {
  synopsis: string;
  title: string;
  course: string;
  challenge: string;
  image?: AssetProperties;
  video?: AssetProperties;
  sections?: FlowSectionProperties[];
  courseInstance?: CourseWithProgress;
  challengeInstance?: ChallengeWithProgress;
  addableChallengeInstance?: ChallengeProperties;
  overrideTitleAndImagery?: boolean;
}

export interface FlowSectionProperties extends StorageProperties {
  data: FlowSectionData;
  meta: Meta;
  id: string;
}

export class FlowSection extends Storage implements FlowSectionProperties {
  public override data!: FlowSectionData;

  constructor(options?: FlowSectionProperties, user?: string) {
    super(options, user, EntityType.FlowSection);
    this.update(options?.data);
  }

  public update(data?: FlowSectionData): void {
    this.data = { ...data } as unknown as FlowSectionData;
    this.data.sections = this.data.sections || [];
  }
}
