import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  AppConfig,
  APP_CONFIG,
  HTTPTraceHeader,
  TraceService,
  SpanTypes,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface WorkflowAPI {
  updateWorkflowState(
    workflowId: string,
    state: string,
    updated: number,
    trace?: HTTPTraceHeader
  ): Observable<void>;
}

@Injectable({
  providedIn: 'root',
})
export class WorkflowService implements WorkflowAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  timeoutMs = 20000;

  retryConfig = {
    retries: 1,
    backoffMs: 1000,
    retryDelayMs: 1000,
    tooBusyRetries: 2,
    checksumRetries: 2,
  };

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  updateWorkflowState(
    workflowId: string,
    state: string,
    updated: number,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.adminUpdateWorkflowState);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.adminUpdateWorkflowState
      );
      internalTrace = true;
    }
    return this.http
      .post<void>(
        `${this.tenantConfig.api.endpoint}/admin/workflow/${workflowId}/${state}/${updated}`,
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminUpdateWorkflowState, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.adminUpdateWorkflowState);
        })
      );
  }
}
