import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AffiliateApprovalStatus } from '@sidkik/db';
import {
  AppConfig,
  APP_CONFIG,
  TraceService,
  HTTPTraceHeader,
  SpanTypes,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface AffliateAPI {
  addAffilate(trace?: HTTPTraceHeader): Observable<void>;
  payAffilate(
    affiliateId: string,
    payoutId: string,
    amount: number,
    trace?: HTTPTraceHeader
  ): Observable<void>;
}

@Injectable({
  providedIn: 'root',
})
export class AffiliateService implements AffliateAPI {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  payAffilate(
    affiliateId: string,
    payoutId: string,
    amount: number,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.affiliatePay);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.affiliatePay
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/admin/affiliate/payment`,
        { affiliate: affiliateId, payout: payoutId, amount },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.affiliatePay, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace && this.traceService.endSpan(SpanTypes.affiliatePay);
        })
      );
  }

  addAffilate(trace?: HTTPTraceHeader): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.affiliateRequestNew);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.affiliateRequestNew
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/admin/affiliate/request`,
        {},
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.affiliateRequestNew, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.affiliateRequestNew);
        })
      );
  }

  processAffilateApproval(
    affiliateId: string,
    approvalStatus: AffiliateApprovalStatus,
    trace?: HTTPTraceHeader
  ): Observable<void> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.affiliateProcessApproval);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.affiliateProcessApproval
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/admin/affiliate/approval`,
        { affiliate: affiliateId, approval: approvalStatus },
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.affiliateProcessApproval, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.affiliateProcessApproval);
        })
      );
  }

  getConnectURLForAffilate(
    trace?: HTTPTraceHeader
  ): Observable<{ URL: string }> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.affiliateConnectURL);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.affiliateConnectURL
      );
      internalTrace = true;
    }
    return this.http
      .post<any>(
        `${this.tenantConfig.api.endpoint}/admin/affiliate/connect`,
        {},
        {
          headers: this.processHeaders(trace),
        }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.affiliateConnectURL, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.affiliateConnectURL);
        })
      );
  }
}
