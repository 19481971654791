import { createAction, props } from '@ngrx/store';
import { StripeAccount, noOperation } from '@sidkik/global';

export const loadStripeAccount = createAction(
  '[tenant-layout] load stripe account'
);

export const noop = createAction(noOperation);

export const loadStripeAccountSuccess = createAction(
  '[tenant-layout] load stripe account success',
  props<{ stripe: StripeAccount }>()
);

export const loadStripeAccountFailure = createAction(
  '[tenant-layout] load stripe account failure',
  props<{ error: any }>()
);
